import { Controller } from "@hotwired/stimulus"
import ClipboardJS from "clipboard";

export default class extends Controller {
  static targets = ["input"]


  connect() {
    console.log("upload controller connected!")
  }

  validate(e) {
    if(e.target.dataset.designType) {
      let file = e.target.files[0]
      let image = new Image();
      image.onload = function() {
        if (this.width > 1500) {
          // for 600 dpi designs
          if (this.width < 1950 || this.height < 4350) {
            alert("this design does NOT include the bleed area. Please re-upload one that does 😁")
            e.target.value = null
          }
        } else {
          // for 300 dpi designs
          if (this.width < 975 || this.height < 2175) {
            alert("this design does NOT include the bleed area. Please re-upload one that does 😁")
            e.target.value = null
          }
        }
      }
      image.src = URL.createObjectURL(file)
    }
  }

  validatePreview(e) {
    if(e.target.dataset.designType) {
      let file = e.target.files[0]
      let image = new Image();
      image.onload = function() {
        if (this.width > 1500) {
          // for 600 dpi designs
          if (this.width > 1800 || this.height > 4200) {
            alert("this preview INCLUDES the bleed area. Please re-upload one that does not 😁")
            e.target.value = null
          }
        } else {
          // for 300 dpi designs
          if (this.width > 900 || this.height > 2100) {
            alert("this preview INCLUDES the bleed area. Please re-upload one that does not 😁")
            e.target.value = null
          }
        }
      }
      image.src = URL.createObjectURL(file)
    }
  }

}
